import "./App.css";
import Soundwave from "./components/Soundwave";

function App() {
  return (
    <div className="App">
      <div className="container">
        <Soundwave />
      </div>
    </div>
  );
}

export default App;
