// external imports

// internal imports
import { useRef } from "react";
import { useEffect, useState } from "react";
import Audiodrop from "./Audiodrop";
import Colorpicker from "./Colorpicker";
import Subtitle from "./Subtitle";
import Title from "./Title";
import Video from "./Video";

export default function Soundwave() {
  const [img, setImgUrl] = useState(0);
  const [audio, setAudio] = useState(0);
  const [image, setImage] = useState(0);
  const [color, setColor] = useState(0);
  const [email, setEmail] = useState(0);

  const imageUrl = useRef(0);
  useEffect(() => {
    console.log(window.location.search);
    const urlParams = new URLSearchParams(window.location.search);
    const coverid = urlParams.get('coverid');
    const png = urlParams.get('png');
    console.log(png);
    console.log(coverid);

    const coverid2 = "https://www.authorlab.pro/audio/select/upload/"+ coverid +".jpg";
    let  cover = "";
    if(png === "01") {cover="https://img1.niftyimages.com/0sl/058d/334d?png.image=https://www.authorlab.pro/audio/png/01.png&cover.image=" + coverid2;}
    if(png === "02") {cover="https://img1.niftyimages.com/0sl/e58d/t34d?png.image=https://www.authorlab.pro/audio/png/02.png&cover.image=" +  coverid2;}
    if(png === "03") {cover="https://img1.niftyimages.com/0sl/-58d/234d?png.image=https://www.authorlab.pro/audio/png/03.png&cover.image=" +  coverid2;}                
    if(png === "04") {cover="https://img1.niftyimages.com/0sl/c58d/ot4d?png.image=https://www.authorlab.pro/audio/png/04.png&cover.image=" +  coverid2;}
    if(png === "05") {cover="https://img1.niftyimages.com/0sl/k58d/rt4d?png.image=https://www.authorlab.pro/audio/png/05.png&cover.image=" +  coverid2;}
    if(png === "06") {cover="https://img1.niftyimages.com/0sl/158d/5t4d?png.image=https://www.authorlab.pro/audio/png/06.png&cover.image=" +  coverid2;}
    if(png === "07") {cover="https://img1.niftyimages.com/0sl/w58d/7t4d?png.image=https://www.authorlab.pro/audio/png/07.png&cover.image=" +  coverid2;}
    if(png === "08") {cover="https://img1.niftyimages.com/0sl/s58d/pt4d?png.image=https://www.authorlab.pro/audio/png/08.png&cover.image=" + coverid2;}
    console.log(cover);
    setImgUrl(cover);
    setImage(cover);
  }, []);

  const inputHandlerColor = (e) => {
    setColor(e);
  };
  const inputHandlerEmail = (e) => {
    setEmail(e.target.value);
  };
  // const inputHandlerImage = (e) => {
  //   setImage(e);
  // };
  const inputHandlerAudio = (e) => {
    setAudio(e);
  };

  const submitHandle = (e) => {
    e.preventDefault();

    if (audio && color && email) {
      // check email validation
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (pattern.test(email)) {
        const inputObject = {
          audio,
          image,
          color,
          email,
          imagesrc: 'true'
        };

        const recipeUrl = "https://procasttools.com/soundwave/backend/public/api/audiogram";
        const postBody = inputObject;
        const requestMetadata = {
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: JSON.stringify(postBody),
        };

        fetch(recipeUrl, requestMetadata)
          .then((res) => alert("From submited successfully!"))
          .catch((e) => console.log(e.response));
      } else {
        alert("Email not valid!");
      }
    } else {
      alert("All fields are Required!");
    }
  };

  return (
    <div className="Soundwave">
      <Video url="https://player.vimeo.com/video/536593186" />
      <form
        onSubmit={(e) => submitHandle(e)}
        method="post"
        encType="multipart/form-data"
        action="*"
        className="soundwave-form"
      >
        <h1 className="soundwave-form-title">
          Create A Podcast Promo Soundwave Video Clip
        </h1>
        <div className="voice-clip-wrapper">
          <Title name="Upload audio clip (up to 1 minute)" />
          <Subtitle name="Audio clip should be under 60 seconds. mp3 and m4a files accepted." />
          <Audiodrop
            handler={(e) => inputHandlerAudio(e)}
            name="Drag your audio clip here or click to upload."
          />
        </div>

        <div className="photo-wrapper">
          <Title name="Upload image" />
          <Subtitle name="Square image with at least 1080x1080 pixels." />
          {/* <Dorp
            handler={(e) => inputHandlerImage(e)}
            name="Drag your audio clip here or click to upload."
          /> */}
          <img ref={imageUrl} src={img} alt="" />
        </div>

        <div className="color-wrapper">
          <Title name="Your email address" />
          <Colorpicker handler={(e) => inputHandlerColor(e)} />
        </div>

        <div className="text-wrapper">
          <Title name="Your email address" />
          <Subtitle name="We'll notify you by email when your video is ready for download." />
          <div className="col-md-6 center-col">
            <input
              onChange={(e) => inputHandlerEmail(e)}
              type="email"
              name="email"
            />
          </div>
        </div>

        <input type="submit" value="submit" />
      </form>
    </div>
  );
}
