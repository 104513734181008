import { CompactPicker } from "react-color";
import { useEffect, useState } from "react";

export default function Colorpicker({ handler }) {
  const [color, setColor] = useState(0);
  useEffect(() => {
    handler(color);
  }, [handler, color]);
  return (
    <div className="color-picker">
      <CompactPicker
        color={color}
        onChangeComplete={(col) => setColor(col.hex)}
      />
    </div>
  );
}
