import ReactPlayer from "react-player";
export default function Video({ url }) {
  return (
    <div className="video">
      <ReactPlayer controls url={url} />
      <a
        className="externel-link"
        href="https://design.podcastingresults.com/about-us-2/?product_base=20&product_cms=1133"
        target="new"
        rel="noopener noreferrer"
      >
        Create Your Graphic
      </a>
    </div>
  );
}
