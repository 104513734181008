import { SpeakerHigh } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

export default function Audiodrop({ name, handler }) {
  const [files, setFiles] = useState([]);
  useEffect(() => {
    if (files.preview) {
      function getData(audioFile, callback) {
        var reader = new FileReader();
        reader.onload = function (event) {
          var data = event.target.result.split(","),
            decodedImageData = btoa(data[1]); // the actual conversion of data from binary to base64 format
          callback(decodedImageData);
          handler(data);
        };
        reader.readAsDataURL(audioFile);
      }
      getData(files, () => {
        console.log("success");
      });
    }
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "audio/*",
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        const audio = document.createElement("AUDIO");
        audio.src = file.preview;
        audio.addEventListener("loadedmetadata", () => {
          if (audio.duration < 500) {
            setFiles(file);
          } else {
            alert("file is so large!");
          }
        });
      });
    },
  });

  const audio = (
    <div className="drop-img">
      <iframe src={files.preview} frameBorder="0"></iframe>
    </div>
  );
  return (
    <div {...getRootProps()} className="drop">
      <input {...getInputProps()} />
      <SpeakerHigh size={52} weight="fill" />
      <p>{name}</p>
      {files && files.preview && audio}
    </div>
  );
}
